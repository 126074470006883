@font-face {
    font-family: VerizonNHGTX;
    src: url('./fonts/VerizonNHGTX-Regular.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: VerizonNHGTX;
    src: url('./fonts/VerizonNHGTX-Bold.otf') format('opentype');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: ApercuPro;
    src: url('./fonts/Apercu-Regular-Pro.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: ApercuPro;
    src: url('./fonts/Apercu-Bold-Pro.ttf') format('truetype');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: ApercuPro;
    src: url('./fonts/Apercu-Black-Pro.ttf') format('truetype');
    font-weight: bolder;
    font-display: swap;
}

@font-face {
    font-family: ApercuPro;
    src: url('./fonts/Apercu-Light-Pro.ttf') format('truetype');
    font-weight: lighter;
    font-display: swap;
}

body {
    background-color: #fff;
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
}

/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
.dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #db1b1b;
    color: #db1b1b;
    box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    -webkit-animation: dot-typing 1s infinite linear;
    animation: dot-typing 1s infinite linear;
}
@-webkit-keyframes dot-typing {
    0% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    16.667% {
        box-shadow: 9984px -10px 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    33.333% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    50% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px -10px 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    66.667% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    83.333% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px -10px 0 0 #db1b1b;
    }
    100% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
}
@keyframes dot-typing {
    0% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    16.667% {
        box-shadow: 9984px -10px 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    33.333% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    50% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px -10px 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    66.667% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
    83.333% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px -10px 0 0 #db1b1b;
    }
    100% {
        box-shadow: 9984px 0 0 0 #db1b1b, 9999px 0 0 0 #db1b1b, 10014px 0 0 0 #db1b1b;
    }
}

button {
    cursor: pointer;
}
